import React from 'react';
import { putRequestToApi } from '../api';
import { PortalContext } from '../AuthenticatedUser';
import AuthContext from '../context/AuthProvider';
import Button from './Button';

export default function SavedMessage({
  type, 
  icon, 
  header, 
  body, 
  messageId=null, 
  allowSave=false,
  allowRemove=false,
  onRemove,
  highlightedImage=null
}) {

  const { auth, setAuth, cookies } = React.useContext(AuthContext);

  const [showHowTo, setShowHowTo] = React.useState(false)
  const [savedMessage, setSavedMessage] = React.useState(false)
 
  const { messageImage, exerciseMessageImage, setSavedMessages, setSavedMessagePage } = React.useContext(PortalContext)

  const hasHowToMake = (body.includes("<como_fazer>") || body.includes("<comofazer>") || body.includes("<com_fazer>"))

  const onMessageSave = () => {
    setSavedMessage(true)

    saveMessage()

    // reset saved message list to force reloading from backend
    setSavedMessages([])
    setSavedMessagePage(0)
  }

  const allowSavingMessage = allowSave 
 
  async function saveMessage() {

    try {
      
      const requestBody = {
        'id': +cookies.get("userId"),
        'item_id': messageId // format(eatingDate, "MM/dd/yyyy")
      }

      const data = await putRequestToApi(
        requestBody, 
        `message`, 
        auth, 
        cookies, 
      )

      //setResponse(data)
      setAuth(prev => ({
        ...prev, 
        accessToken: data.tokens.accessToken,
        refreshToken: data.tokens.refreshToken
      })) 

    } catch (err) {
      console.log(err)
      console.log("Error trying to save picture")
    } 
  }

  const saveButton = () => {
    if (savedMessage) {
      return (
        <p className="body secondary-color">Salva!</p>
      )
    } else { 
      return (
        <Button type="tertiary message-background" onClick={onMessageSave}>
          Salvar mensagem
        </Button> 
      )
    } 
  }

  let message = null
  let howToDo = null
  if (hasHowToMake) {
    if (body.includes("<como_fazer>")) {
      message = body.slice(0, body.indexOf("<como_fazer>")).trim()
      howToDo = body.slice(body.indexOf("<como_fazer>")+12).trim()
    } else if (body.includes("<comofazer>")) {
      message = body.slice(0, body.indexOf("<comofazer>")).trim()
      howToDo = body.slice(body.indexOf("<comofazer>")+11).trim()
    } else if (body.includes("<com_fazer>")) {
      message = body.slice(0, body.indexOf("<com_fazer>")).trim()
      howToDo = body.slice(body.indexOf("<com_fazer>")+11).trim()
    }
  } else {
    message = body
  }

  let imageUrl = null
  if (highlightedImage) {
    imageUrl = window.URL.createObjectURL(highlightedImage)
  } else {
    if (messageImage && messageImage.id === messageId && messageImage.image !== null) {
      imageUrl = window.URL.createObjectURL(messageImage.image)
    } else if (exerciseMessageImage && exerciseMessageImage.id === messageId && exerciseMessageImage.image !== null ) {
      imageUrl = window.URL.createObjectURL(exerciseMessageImage.image)
    }
  }

  if (imageUrl) {
    return (
      <div 
        className="florence-message message-background-container top-margin-s bottom-margin-s"
      >
        <div className="padding-s">
            {(type === "message-no-icon") ? null : <img className="menu-icon" src={icon}/> }
            <div style={{
              display: "flex", 
              flexDirection: "row", 
              justifyContent: "space-between",
              alignItems: "end"
            }}
            >
              <div className="top-margin-xs">{header}</div>
              {(allowSavingMessage && messageId && type==="message") ? saveButton() : null }
              {(allowRemove && messageId && type==="message") ? 
                <Button type="tertiary message-background" onClick={onRemove}>Remover</Button>
              : null }
            </div>
        </div>
        
        <img src={imageUrl} style={{width: "100%", borderRadius: "16px"}}/>
      
        <p className={`secondary-text text-wrapping horizontal-padding-s top-margin-s ${(hasHowToMake) ? "bottom-margin-xs" : "bottom-margin-s"}`}>
          {message}
        </p>

        {(hasHowToMake) ? 
        <div className="horizontal-padding-s bottom-margin-s">

          <Button type="tertiary btn-smaller message-background" onClick={()=>setShowHowTo(prev=>!prev)}>
            {(showHowTo) ? "Esconder como fazer" : "Ver como fazer"}
          </Button>

          {showHowTo ?
          <p className="secondary-text text-wrapping">
            {howToDo}
          </p> : null }
        </div>
        : null }
      </div>
    )
  }

  return (
    <div className={type === "warning" ? 
      "florence-warning top-margin-s bottom-margin-xs" :
      "florence-message top-margin-s bottom-margin-xs"}>

      <div className="padding-s">
        <div>
            {(type === "message-no-icon") ? null : <img className="menu-icon" src={icon}/> }
            <div style={{
              display: "flex", 
              flexDirection: "row", 
              justifyContent: "space-between",
              alignItems: "end"
            }}
            >
              <div className="top-margin-xs">{header}</div>
              {(allowSavingMessage && messageId && type==="message") ? saveButton() : null }
              {(allowRemove && messageId && type==="message") ? 
                <Button type="tertiary message-background" onClick={onRemove}>Remover</Button>
              : null }
            </div>
        </div>
        
        <p className={`secondary-text text-wrapping top-padding-xs ${(hasHowToMake) ? "bottom-margin-xs" : ""}`}>
          {message}
        </p>

        {(hasHowToMake) ? 
        <div>

          <Button type="tertiary btn-smaller message-background" onClick={()=>setShowHowTo(prev=>!prev)}>
            {(showHowTo) ? "Esconder como fazer" : "Ver como fazer"}
          </Button>

          {showHowTo ?
          <p className="secondary-text text-wrapping">
            {howToDo}
          </p> : null }

        </div>
        : null }

      </div>
        
      </div>
  )
}
