import React from 'react'

export default function PhotoThumbnail({ photo, expanded=false }) {

  let imageUrl = null
  if (!photo.hasImage) {
    return null
  }

  imageUrl = window.URL.createObjectURL(photo.image)

  return (
    <div key={photo.id}>
      <img className={(!expanded) ? "photo-thumbnail" : "photo-expanded"} src={imageUrl} alt="Photo thumbnail"/>
    </div>
  )
}
