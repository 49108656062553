import React from "react"
import { PortalContext } from "../../AuthenticatedUser"
import { useMediaQuery } from 'react-responsive'
import Button from "../Button"
import { postRequestToApi, registerAction } from "../../api"
import AuthContext from "../../context/AuthProvider"
import PhotoCamera from '../../images/photo-camera.svg'
import PhotoGrid from "../PhotoGrid"
import { tab } from "@testing-library/user-event/dist/tab"

export default function PhotoEvolutionMessage({ clickNext }) {

  const { auth, setAuth, cookies } = React.useContext(AuthContext);
  const { setEating } = React.useContext(PortalContext)
  
  const [postImage, setPostImage] = React.useState(null)
  const [status, setStatus] = React.useState('idle')
  
  const smallMobileBreakpoint = useMediaQuery({ query: '(max-width: 500px)' })
  const tabletBreakpoint = useMediaQuery({ query: '(min-width: 768px)' })


  React.useEffect(() => {
    registerAction(+cookies.get("userId"), "photoEvolutionMessagePage", auth, cookies)
  }, [])
  
  function handleImageChange(e) {
    const target = e.target

    setPostImage(target.files[0])
  }

  function handleSubmit(e) {
    e.preventDefault()

    console.log('submitting')
    submitImage()
    clickToContinue()
  }
  
  async function submitImage() {

    try {
      setStatus("submitting")
      
      const imageFormData = new FormData()
      imageFormData.append('photo', postImage)


      const data = await postRequestToApi(
        imageFormData, 
        `/recordphoto/${cookies.get("userId")}`, 
        auth, 
        cookies, 
        true, 
        false, 
      )

      //setResponse(data)
      setAuth(prev => ({
        ...prev, 
        accessToken: data.tokens.accessToken,
        refreshToken: data.tokens.refreshToken
      })) 
    } catch (err) {
      console.log("Error submitting photo")
      setStatus(err)
    } finally {
      setStatus("idle")
      //navigate('/exercise/highlight', { state: { message: "Seu exercício físico foi submetida com sucesso, e em alguns instantes ele aparecerá nessa página" }})
    }
  }
  
  const clickToContinue = () => {
    // update objective check in flag
    const bodyRequest = {id: +cookies.get("userId")}
    postRequestToApi(bodyRequest, `goalscheckinseen`, auth, cookies)
    setEating(prev => ({...prev, submission_pages: {objective_check_in: false}}))
    
    registerAction(+cookies.get("userId"), "photoEvolutionMessageContinue", auth, cookies)

    clickNext()
  }
  
  let imageUrl = null
  if (postImage) {
    imageUrl = window.URL.createObjectURL(postImage)
  }

  return (
    <div className={(smallMobileBreakpoint) ? "container-full-width" : "container-simple"}>
      <div className={(smallMobileBreakpoint) ? "container-simple" : ""}>
        <div className="top-margin-m icon-center">
          <img className="medium-logo" 
              src={require("../../images/florence-white-blue.png")} alt="Florence logo"/>
          <p class="heading4 bottom-margin-xs">Sua evolução em fotos</p> 
          <p class="body bottom-margin-l">
            Guarde fotos para ver sua evolução ao longo do tempo
          </p>
        </div>
          
        <div style={{textAlign: "center"}}>
          {(imageUrl) ? <img className="submit-photo-preview" src={imageUrl} alt="Submission image"/> :
          <img className="submission-image-small" src={PhotoCamera} alt="Submission image"/>
          }
        </div>

        <form className={(tabletBreakpoint) ? 
          "pair-btn-row bottom-margin-xl top-margin-l" : "pair-btn-row bottom-margin-m top-margin-l"}
          onSubmit={handleSubmit}
        >
          {(imageUrl) ?
          <Button 
            type={(smallMobileBreakpoint) ? "primary btn-wide btn-no-padding btn-taller" :"primary btn-wide btn-taller"}
          >
            {(status === 'idle') ? 'Adicionar' : 'Adicionando' }
          </Button> : null }

          <Button 
            type={(smallMobileBreakpoint) ? "primary btn-wide btn-no-padding btn-taller" : "primary btn-wide btn-taller"}
            buttonType="button"
          >
            <label for="upload-photo">
              {(imageUrl) ? "Tirar outra" : "Tirar foto"}
            </label>
          </Button>

          <div>
            <input type="file"
              className="bottom-margin-s"
              id="upload-photo" 
              name="image" 
              accept="image/png, image/gif, image/jpeg, image/jpg" 
              onChange={handleImageChange}
              />
          </div>


          <Button 
            type={(smallMobileBreakpoint) ? "secondary btn-wide btn-no-padding btn-taller" :"secondary btn-wide btn-taller"}
            buttonType="button"
            onClick={clickToContinue}
          >
            {(imageUrl) ? "Agora não" : "Continuar"}
          </Button>
        </form>
      </div>

      <PhotoGrid />

    </div>
  )
}
