import React from "react"
import { PortalContext } from "../../AuthenticatedUser"
import Button from "../Button"
import HighlightedMessage from "../HighlightedMessage"
import Bulb from "../../images/bulbDark.svg"
import { registerAction } from "../../api"
import SavedMessage from "../SavedMessage"

export default function FlorenceMessage({ clickNext, submitionItem }) {
  
  const { eating, exercise, auth, cookies } = React.useContext(PortalContext)

  React.useEffect(() => {
    registerAction(+cookies.get("userId"), "florenceMessagePage", auth, cookies)
  }, []);

  if (submitionItem === "refeição" && !eating.message) {
    clickNext()
    return null
  } else if (submitionItem === "exercício" && !exercise.message) {
    clickNext()
    return null
  }

  return (
    <div className={"container-simple"}>
      <div className="column-middle-page">
        <div className="icon-center">
          <img className="medium-logo" 
              src={require("../../images/florence-white-blue.png")} alt="Florence logo"/>
        </div>

        <SavedMessage 
          icon={Bulb} type="message" 
          header="Mensagem da Florence" 
          body={(submitionItem === "refeição") ? eating.message : exercise.message } 
          messageId={(submitionItem === "refeição") ? eating.message_id : exercise.message_id }
          allowSave={true}
        />
        <div className="top-margin-s bottom-margin-s" style={{maxWidth: "450px", textAlign: 'right'}}>
          <Button type="primary" onClick={()=>{clickNext()}}>Próximo</Button>
        </div>
      </div>
    </div>
  )
}
