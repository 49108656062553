import { format } from 'date-fns';
import React from 'react';
import { useMediaQuery } from 'react-responsive'
import { deleteDataFromApi, postRequestToApi, registerAction } from '../api';
import { PortalContext } from '../AuthenticatedUser';
import AuthContext from '../context/AuthProvider';
import Button from './Button';
import PhotoThumbnail from './PhotoThumbnail';
import PhotoCamera from '../images/photo-camera.svg'

export default function PhotoGrid({ add=false }) {
  
  const { auth, setAuth, cookies } = React.useContext(AuthContext);
  const {
    userPhotos,
    setUserPhotos,
    loadingUserPhotos,
    hasMoreUserPhotos,
    setUserPhotosPage,
    setLoadingUserPhotos,      
    setHasMoreUserPhotos,
    loadUserPhotos
  } = React.useContext(PortalContext);
  
  const [selectedImage, setSelectedImage] = React.useState(null)

  const tabletBreakpoint = useMediaQuery({ query: '(min-width: 768px)' })
  
  React.useEffect(() => { 
    registerAction(+cookies.get("userId"), "photoGridLoaded", auth, cookies)

    resetPhotos()
  }, [])
  
  const resetPhotos = async () => {
    if (userPhotos.length === 0) {
      // reset saved page to 0
      setUserPhotosPage(0)
      // reset saved messages to []
      setUserPhotos([])
      // reset hasMore and loadingSave to true
      setLoadingUserPhotos(true)
      setHasMoreUserPhotos(true)

      // ask to load message 
      await loadUserPhotos(0)
    }
  }
  
  const observer = React.useRef()

  const lastMessageElementRef = React.useCallback(node =>
    {
      if (loadingUserPhotos) return
      if (observer.current) {
        observer.current.disconnect()
      }
      observer.current = new IntersectionObserver(entries => {
        if (entries[0].isIntersecting && hasMoreUserPhotos) {
          setUserPhotosPage(prevPageNumber => prevPageNumber + 1)
          console.log('message visible')
        }
      })
      if (node) observer.current.observe(node)

    }, [loadingUserPhotos, hasMoreUserPhotos])
  
  async function deletePhotoFromBackend(requestBody) {

   await deleteDataFromApi(requestBody, `/recordphoto/${cookies.get("userId")}`, auth, cookies) 
  }
  
  const photoRemove = (id) => {
    
    setUserPhotos(prev => [...prev.filter(item => item.id !== id)])
    setSelectedImage(null)

    const requestBody = {
        item_id: id 
    }

    deletePhotoFromBackend(requestBody)
  }

  const selectPhoto = (photo) => {
    if (selectedImage && photo.id === selectedImage.id) {
      setSelectedImage(null)
    } else {
      setSelectedImage(photo)
    }
  }
  
  if (!userPhotos) {
    return (
      <div className={(tabletBreakpoint) ? "container-simple-tablet" : "container-simple"}
        style={{height: "100vh"}}
      >
        <p>Carregando fotos...</p>
      </div>
    )
  }
  
  function handleImageSelection(e) {
    const target = e.target

    const newImage = {
      id: null, 
      created_at: new Date(), 
      hasImage: true,
      image: target.files[0]
    }

    setUserPhotos(prev => [newImage, ...prev])

    submitImage(target.files[0])
  }
  
  async function submitImage(postImage) {
    try {
      const imageFormData = new FormData()
      imageFormData.append('photo', postImage)

      const data = await postRequestToApi(
        imageFormData, 
        `/recordphoto/${cookies.get("userId")}`, 
        auth, 
        cookies, 
        true, 
        false, 
      )

      //setResponse(data)
      setAuth(prev => ({
        ...prev, 
        accessToken: data.tokens.accessToken,
        refreshToken: data.tokens.refreshToken
      })) 

    } catch (err) {
      console.log("Error submitting photo")
      console.log(err)
    } 
  }
  
  // avoid duplicates
  const visibleItemsIds = []
  const photosToRender = []

  userPhotos.forEach(photo => {
    if (photo.id !== null && visibleItemsIds.includes(photo.id)) {
      return null
    } else {
      visibleItemsIds.push(photo.id)
      photosToRender.push(photo)
    }
  })

  return (
    <>
    {(selectedImage) ?
      <div  className="body secondary-color left-margin-xs bottom-margin-s">
        <PhotoThumbnail photo={selectedImage} expanded={true} />
        <span className="body secondary-color">
          Adicionado em {format(selectedImage.created_at, "d/M/yyyy")}
        </span>
        {selectedImage.id &&
        <Button type="tertiary left-margin-xs" onClick={()=>photoRemove(selectedImage.id)}>Remover</Button> }
      </div> 
      : null
    }
    <div className="photo-grid">
      {(add) ?
          <button className="svg-btn">
            <label for="upload-photo">
              <div className="photo-thumbnail">
                <img className="submission-image-small" src={PhotoCamera} alt="Submission image"/> 
              </div>
            </label>
          </button>
        : null 
      }
      { 
        photosToRender.map((photo, index) => {
          if (photosToRender.length === index + 1) { // last message
            return (
              <button key={photo.id} className="svg-btn" onClick={()=>selectPhoto(photo)}>
                <div ref={lastMessageElementRef} key={photo.id}>
                  <PhotoThumbnail photo={photo} />
                </div>
              </button>
            )
          } else {
            return (
              <button key={photo.id} className="svg-btn" onClick={()=>selectPhoto(photo)}>
                <PhotoThumbnail photo={photo} />
              </button>
            )
          }
        }) 
      }
    </div>
    <input type="file"
      className="bottom-margin-s"
      id="upload-photo" 
      name="image" 
      accept="image/png, image/gif, image/jpeg, image/jpg" 
      onChange={handleImageSelection}
      />
    </>
  )
}
