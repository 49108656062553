import React from "react"
import { useNavigate, NavLink } from "react-router-dom"
import smallMenuLogo from "../images/bars-solid.svg"
import closeMenuLogo from "../images/x-solid.svg"
import AuthContext from '../context/AuthProvider'
import { deleteDataFromApi, registerAction } from "../api"
import { format } from "date-fns"

export default function ToggleMenu({menuItems, id, notifications=[]}) {

  const [menuState, setMenuState] = React.useState(true)

  const { auth, setAuth, cookies } = React.useContext(AuthContext);
  
  const navigate = useNavigate()

  const numberOfNotifications = notifications.length

  function toggleMenuState() {
    setMenuState(prev => !prev)

    if (numberOfNotifications > 0) {
      deleteNotifications()
    }
    registerAction(+cookies.get("userId"), "toggleHeaderMenu", auth, cookies)
  }

  let menuOptions = []
  if (!menuItems) {
    menuOptions = [
      {key: 1, element: <>Entrar em sua conta</>, link: "/loginrequired"},
      {key: 2, element: <>Acessar dados compartilhados</>, link: "/accessshared"},
      {key: 3, element: <>Cadastre-se</>, link: "/registration"},
      {key: 2, element: <>Fórum</>, link: "/forum"},
      {key: 4, element: <>Quem somos</>, link: "/about"}
    ]
  } else if (menuItems === "family") {
    menuOptions = [
      {key: 1, element: <>Família</>, link: "/family"},
      {key: 2, element: <>Alterar Família</>, link: "/family/manage"},
      {key: 4, element: <>Sair</>, link: "/loguserout"}
    ] 
  } else if (menuItems === "profile" || menuItems === "portal") {
    menuOptions = [
      {key: 0, element: <>Página Inicial</>, link: `/profile`},
      {key: 9, element: <>Minhas fotos</>, link: `/photos`},
      {key: 9, element: <>Mensagens salvas</>, link: `/messages`},
      {key: 5, element: <>Fórum</>, link: `/forum`},
      {key: 2, element: <>Saúde</>, link: `/health`},
      {key: 1, element: <>Família</>, link: "/family"},
      {key: 8, element: <>Minha Conta</>, link: `/account`},
      {key: 7, element: <>Fale Conosco</>, link: `/help`},
      {key: 6, element: <>Sair</>, link: "/loguserout"}
    ]
  } else if (menuItems === "forum") {
    menuOptions = [
      {key: 0, element: <>Página Inicial</>, link: `/profile`},
      {key: 9, element: <>Minhas fotos</>, link: `/photos`},
      {key: 9, element: <>Mensagens salvas</>, link: `/messages`},
      {key: 5, element: <>Fórum</>, link: `/forum`},
      {key: 2, element: <>Saúde</>, link: `/health`},
      {key: 1, element: <>Família</>, link: "/family"},
      {key: 8, element: <>Minha Conta</>, link: "/account"},
      {key: 7, element: <>Fale Conosco</>, link: `/help`},
      {key: 6, element: <>Sair</>, link: "/loguserout"}
    ] 
  } else if (menuItems === "shared") {
    menuOptions = [
      {key: 6, element: <>Sair</>, link: "/logshareduserout"}
    ] 
  } 

  const menu = menuOptions.map(({key, element, link, stateValue}) => {
    return ((link==="/family") ?
        <NavLink 
          key={key}
          className={({isActive}) => isActive ? 
            "menu-link menu-link-selected right-padding-xs" : "menu-link right-padding-xs"
          } 
          to={link} end
          state={(stateValue) ? {search: stateValue} : null }>{element}</NavLink>
        :
        <NavLink 
          key={key}
          className={({isActive}) => isActive ? 
            "menu-link menu-link-selected right-padding-xs" : "menu-link right-padding-xs"
          } 
          to={link} 
          state={(stateValue) ? {search: stateValue} : null }>{element}</NavLink>
    )
  })

  async function deleteNotifications() {
    // mark notifications as seen
    try {
      const data = await deleteDataFromApi(
      '', 
      `/forum/notifications/${cookies.get("userId")}`, 
      auth, cookies)
      setAuth(prev => ({
        ...prev, 
        accessToken: data.tokens.accessToken,
        refreshToken: data.tokens.refreshToken
      }))
    } catch (err) {
      console.log("Error deleting notifications from user")
      console.log(err)
    }
  }
  
  function linkButton(to) {
    navigate(to)
    toggleMenuState()
  }

  const notificationLinks = notifications.map(item => (
    <div className="top-padding-xxs">
      <button className="notification-link" id={item.id} onClick={()=>linkButton(`/forum/${item.post_id}`)}>
        <div className="secondary-text primary-color text-right">{item.content}</div>
        <div className="secondary-text bottom-padding-xxs text-right">{format(item.created_at, "dd/MM/yyyy")}</div>
      </button>
    </div>
  ))

  let notificationMenuSection = null
  if (numberOfNotifications > 0) {
    notificationMenuSection = (
      <div className="notification-menu-section">
        <p className="small-title">Notificações</p>
        {notificationLinks}
      </div>
    )
  } 
  return (
    <div className="top-padding-xxs">
      { 
      menuState ? (
        <button className="svg-btn" onClick={()=>toggleMenuState()}>
          <img className="small-menu-nav" src={smallMenuLogo} alt="Button to toggle menu" />
          {(numberOfNotifications > 0) ?
            (numberOfNotifications > 9) ? 
              <div className="notification-flag-large">+9</div> : 
              <div className="notification-flag-small">{numberOfNotifications}</div>
            : null
          }
        </button>
        ) : (
          <div className={(numberOfNotifications > 0) ? "menu-notifications bottom-padding-s" : "menu bottom-padding-s"}>
            <button className="svg-btn" onClick={()=>toggleMenuState()}>
                <img className="small-menu-nav inv-svg" src={closeMenuLogo} alt="Button to toggle menu" />
            </button>
            {notificationMenuSection}
            {menu}
          </div>
        )
      }
    </div>
  )
}
