import React from 'react';
import { useMediaQuery } from 'react-responsive'
import Header from "../components/Header"
import { PortalContext } from '../AuthenticatedUser'
import AuthContext from "../context/AuthProvider"
import { useNavigate } from 'react-router-dom';
import { registerAction } from '../api';
import PhotoGrid from '../components/PhotoGrid';

export default function UserImages() {

  const { auth, cookies } = React.useContext(AuthContext);
  const {
    loadEatingDataFromAPI,
    sequenceLength,
    league,
    eating,
  } = React.useContext(PortalContext);
  
  const smallMobileBreakpoint = useMediaQuery({ query: '(max-width: 500px)' })
  const tabletBreakpoint = useMediaQuery({ query: '(min-width: 768px)' })

  const navigate = useNavigate()

  React.useEffect(() => { 
    if (!cookies.get("userId")) {
      navigate("/family")
    } else {
      if (!eating) {
        loadEatingDataFromAPI()
      }

    }
    registerAction(+cookies.get("userId"), "userimagespage", auth, cookies)
  }, [])


  return (
    (!eating || !eating?.history) ? 
      <div className="icon-loading-middle-page">
        <img className="small-logo-nav loading-icon" 
            src={require("../images/florence-white-blue.png")} alt="Florence logo"/>
        <p>Carregando página...</p> 
      </div>
    :
    <div style={{
        display: "flex", 
        flexDirection: "column", 
        justifyContent: "start", 
        minHeight: '100vh'}}>
      <div className={(tabletBreakpoint) ? "container-simple-tablet" : "container-simple"}>
        <Header tabletBreakpoint={false} 
          menuItems="profile" 
          id={+cookies.get("userId")} 
          menuShow={true}
          selected="user-home"
          sequenceLength={sequenceLength}
          hasObjectives={(eating && eating?.goals) ? eating.goals.length > 0 : false}
          league={(league?.is_in_current_league) ? league.is_in_current_league : false}
        />
      </div>

      <div className={"container-simple"}>
        <p className="florence-title top-margin-s bottom-margin-m"> 
          Minhas fotos
        </p>
      </div>

      <div className={(smallMobileBreakpoint) ? "container-full-width" : "container-simple"}>
        <PhotoGrid add={true} />
      </div>
    </div>
  )
}
